* {
  box-sizing: border-box; }

html,
body {
  width: 100%;
  height: 100%; }

body {
  margin: 0;
  font-family: 'Raleway', 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: 0.15rem; }

h1,
h2 {
  letter-spacing: 1px;
  /* Override Skeleton */ }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #364b5f; }

p {
  font-size: 1.6rem; }

img {
  max-width: 100%;
  max-height: 100%; }

.image-caption {
  font-weight: 200;
  color: #a9a9a5; }

.row {
  margin-right: 0 !important;
  margin-left: 0 !important; }

a {
  text-decoration: none; }

button {
  margin-bottom: 0; }

figure {
  margin: 0; }

.placeholder {
  width: 100%;
  height: auto;
  filter: blur(20px); }

.hero {
  height: 50rem;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center; }
  .hero .content {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 2rem;
    text-align: center; }
    .hero .content p {
      font-size: 110%; }
  @media (min-width: 48em) {
    .hero .content {
      max-width: 75%;
      padding: 3rem; }
      .hero .content p {
        font-size: 150%; } }
  @media (min-width: 64em) {
    .hero .content {
      padding: 4rem;
      max-width: 60%; } }

.featured-image {
  text-align: center;
  margin: 2rem 0; }

.video {
  margin-top: 5rem;
  margin-bottom: 5rem; }
  .video iframe {
    margin: 0 auto;
    display: block;
    width: 100%;
    min-height: 30rem; }
  @media (min-width: 48em) {
    .video iframe {
      min-height: 40rem;
      width: 90%; } }
  @media (min-width: 64em) {
    .video iframe {
      min-height: 50rem;
      width: 80%; } }
